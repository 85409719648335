import React from 'react';

import {
  IconButton as IconButtonTPA,
  IconButtonProps,
} from 'wix-ui-tpa/cssVars';

interface IProps extends IconButtonProps {}

export function IconButton(props: IProps) {
  const { ...rest } = props;

  return <IconButtonTPA {...rest} />;
}

IconButton.displayName = 'wui/IconButton';
