import React from 'react';
import { useSelector } from 'react-redux';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useHref } from '@wix/tpa-router/react';
import {
  groupActionClick,
  groupsRequestJoinAGroup,
} from '@wix/bi-logger-groups/v2';

import {
  selectGroup,
  selectGroupPrivacyStatus,
  selectIsGroupSecret,
} from 'store/selectors';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { IconButton } from 'wui/IconButton';
import { ShareDialog } from 'wui/ShareDialog';
import { Typography } from 'wui/Typography';
import { Share as ShareIcon } from '@wix/wix-ui-icons-common/on-stage';

import { GroupMembershipButton } from 'common/components/GroupMembership';
import { GroupInfo } from 'common/components/GroupInfo';
import { RoleIcon } from 'common/components/RoleIcon';

import { InviteMembers } from '../InviteMembers';
import { AddInviteMembers } from '../AddInviteMembers';
import { GroupActions } from '../GroupActions';

import { DETAILS, DETAILS_TITLE } from './dataHooks';
import classes from './GroupDetails.scss';

interface IGroupDetailsProps {
  groupId: string;
}

export function GroupDetails(props: IGroupDetailsProps) {
  const { groupId } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();

  const group = useSelector(selectGroup(groupId));
  const isSecretGroup = useSelector(selectIsGroupSecret(groupId));
  const privacyStatus = useSelector(selectGroupPrivacyStatus(props.groupId));

  const [isOpen, setIsOpen] = React.useState(false);
  const splitMembersPermissions = experiments.enabled(
    'specs.groups.SplitAddMembersPermissionsOOI',
  );

  const groupUrl = useHref(
    'group.discussion.feed',
    { slug: group.slug },
    { absolute: true },
  );

  if (isMobile) {
    return (
      <Box
        gap="SP3"
        direction="vertical"
        data-hook={DETAILS}
        className={classes.mobile}
      >
        <Box direction="vertical">
          <Typography
            as="h1"
            variant="p2-16"
            id="group-title"
            className={classes.title}
            data-hook={DETAILS_TITLE}
          >
            {group.name}
            <RoleIcon role={group.role} />
          </Typography>
          <Typography as="div" variant="p2-14" className={classes.info}>
            <GroupInfo misc wired groupId={groupId} />
          </Typography>
        </Box>

        <Box direction="vertical">
          <Show if={splitMembersPermissions}>
            <AddInviteMembers groupId={groupId} />
          </Show>
          <Hide if={splitMembersPermissions}>
            <InviteMembers groupId={groupId} />
          </Hide>

          <GroupMembershipButton
            groupId={groupId}
            fullWidth={isMobile}
            bi={groupsRequestJoinAGroup({
              group_id: props.groupId,
              origin: 'group_feed_top_banner_btn',
              type: (privacyStatus || '').toLowerCase(),
            })}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box
      gap="SP3"
      width="100%"
      verticalAlign="middle"
      align="space-between"
      data-hook={DETAILS}
    >
      <Box direction="vertical">
        <Typography
          as="h1"
          variant="p2-16"
          id="group-title"
          className={classes.title}
          data-hook={DETAILS_TITLE}
        >
          {group.name}
          <RoleIcon role={group.role} />
        </Typography>
        <Typography as="div" variant="p2-14" className={classes.info}>
          <GroupInfo wired groupId={groupId} />
        </Typography>
      </Box>
      <Box verticalAlign="middle" gap="SP3">
        <Show if={splitMembersPermissions}>
          <AddInviteMembers groupId={groupId} />
        </Show>

        <Hide if={splitMembersPermissions}>
          <InviteMembers groupId={groupId} />
        </Hide>

        <GroupMembershipButton
          groupId={groupId}
          fullWidth={isMobile}
          bi={groupsRequestJoinAGroup({
            group_id: props.groupId,
            origin: 'group_feed_top_banner_btn',
            type: (privacyStatus || '').toLowerCase(),
          })}
        />

        <Hide if={isMobile || isSecretGroup}>
          <IconButton
            aria-label={t('groups-web.a11y.share-group')}
            data-hook="share-button"
            icon={<ShareIcon />}
            className={classes.iconButton}
            onClick={handleOpenShareModal}
          />
        </Hide>
        <Hide if={isMobile}>
          <GroupActions groupId={groupId} />
        </Hide>
      </Box>

      <ShareDialog
        isOpen={isOpen}
        data={{ url: groupUrl }}
        onClose={handleCloseShareModal}
        title={t('groups-web.group.actions.share.group')}
      />
    </Box>
  );

  function handleCloseShareModal() {
    setIsOpen(false);
  }

  function handleOpenShareModal() {
    setIsOpen(true);

    bi.report(
      groupActionClick({
        origin: 'arrow',
        action: 'share',
        group_id: groupId,
      }),
    );
  }
}

GroupDetails.displayName = 'GroupDetails';
