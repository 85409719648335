import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { groupsAddMemberClicked, inviteSent } from '@wix/bi-logger-groups/v2';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Hide } from 'wui/Hide';
import { Button } from 'wui/Button';
import { TextField } from 'wui/TextField';
import { InputDialog } from 'wui/InputDialog';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';
import { IconButton } from 'wui/IconButton';
import { List } from 'wui/List';
import { ListItem } from 'wui/ListItem';
import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemAction } from 'wui/ListItemAction';
import { ListItemText } from 'wui/ListItemText';
import {
  CloseSmall as CloseIconSmall,
  Email as EmailIcon,
  Search as SearchIcon,
} from '@wix/wix-ui-icons-common/on-stage';

import {
  selectAddStatuses,
  selectInviteStatuses,
  selectIsGroupPending,
  selectIsGroupSecret,
  selectMemberLabel,
  selectMembersAddWidgetLabel,
  selectSuggestedMembersMetadata,
} from 'store/selectors';

import { useController } from 'common/context/controller';
import { isValidEmail } from 'common/utils/utils';

import { SuggestedMembersList } from './SuggestedMembersList';
import { INVITE_MEMBERS_MODAL, SEARCH_INPUT } from './dataHook';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  groupId: string;
}

export function InviteMembersDialog(props: IProps) {
  const { groupId, ...rest } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { members$ } = useController();
  const { isMobile } = useEnvironment();

  const inviteStatus = useSelector(selectInviteStatuses);
  const addStatus = useSelector(selectAddStatuses);
  const isSecret = useSelector(selectIsGroupSecret(groupId));
  const isPending = useSelector(selectIsGroupPending(groupId));
  const suggested = useSelector(selectSuggestedMembersMetadata);

  const { label: membersLabel } = useSelector(selectMemberLabel(groupId));
  const { label: membersAddWidgetLabel } = useSelector(
    selectMembersAddWidgetLabel(groupId),
  );

  const [selected, setSelected] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const emails = useMemo<string[]>(() => {
    return _.uniq(
      search
        .split(',')
        .map((email) => email.trim())
        .filter(isValidEmail) || [],
    );
  }, [search]);

  const isLoading =
    selected.some((id) => addStatus[id]?.loading) ||
    emails.some((email) => inviteStatus[email]?.loading);

  if (isPending) {
    return (
      <AlertDialog {...rest}>
        <DialogTitle
          alert
          title={t('groups-web.discussion.can-not-add-members-popup.title')}
        />
        <DialogContent alert>
          <DialogContentText alert>
            {t('groups-web.discussion.can-not-add-members-popup.subtitle')}
          </DialogContentText>
        </DialogContent>
      </AlertDialog>
    );
  }

  return (
    <InputDialog paperProps={{ 'data-hook': INVITE_MEMBERS_MODAL }} {...rest}>
      <DialogTitle title={t(membersAddWidgetLabel, { membersLabel })} />
      <Box padding="SP0 SP6" direction="vertical">
        <TextField
          value={search}
          withClearButton
          prefix={<SearchIcon />}
          placeholder={getPlaceholder()}
          onClear={handleSearchClear}
          onChange={handleSearchChange}
          data-hook={SEARCH_INPUT}
        />
      </Box>
      <DialogContent divider disableSideGutters={isMobile}>
        <Hide if={!!emails.length}>
          <SuggestedMembersList
            groupId={groupId}
            search={search}
            selected={selected}
            onSelect={handleSelect}
          />
        </Hide>
        <Show if={!!emails.length}>
          <List disablePadding>
            {emails.map((email) => (
              <ListItem key={email}>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText title={email} titleProps={{ noWrap: true }} />
                <ListItemAction>
                  <IconButton
                    icon={<CloseIconSmall />}
                    onClick={handleInviteRemove(email)}
                    aria-label={t('groups-web.a11y.close')}
                  />
                </ListItemAction>
              </ListItem>
            ))}
          </List>
        </Show>
      </DialogContent>
      <DialogActions>
        <Button
          outlined
          variant="basic"
          fullWidth={isMobile}
          onClick={props.onClose}
        >
          {t('groups-web.cancel')}
        </Button>
        <Button
          variant="basic"
          disabled={isLoading}
          loading={isLoading}
          fullWidth={isMobile}
          onClick={handleSubmit}
        >
          {t('groups-web.dialogs.invite-members.submit.label')}
        </Button>
      </DialogActions>
    </InputDialog>
  );

  function handleInviteRemove(email: string) {
    return function () {
      setSearch(emails.filter((e) => e !== email).join(', '));
    };
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  function handleSelect(selected: string[]) {
    setSelected(selected);
  }

  function handleSearchClear() {
    setSearch('');
  }

  function handleSubmit() {
    if (emails.length) {
      members$.inviteByEmail(groupId, emails);
      bi.report(
        inviteSent({
          origin: 'wix',
          group_id: groupId,
          contact: emails.join(', '),
        }),
      );
    } else {
      members$.add(groupId, selected);
      bi.report(
        groupsAddMemberClicked({
          groupId,
          origin: 'modal_plus_add_btn',
        }),
      );
    }
  }

  function getPlaceholder() {
    let key: string;

    if (isSecret) {
      key = 'groups-web.secret-group.add.members.search.placeholder_icu';
    } else {
      key = suggested.metadata.count
        ? 'groups-web.add.members.search.placeholder_icu'
        : 'groups-web.add.members.search.no-site-members.placeholder';
    }
    return t(key, { count: suggested.metadata.count as number });
  }
}

InviteMembersDialog.displayName = 'InviteMembersDialog';
