import { createSelector } from 'reselect';
import { curryRight } from 'lodash';

import type { IRootState } from '../types';

import { selectors } from './adapter';

export const selectFeed = createSelector(
  (state: IRootState) => state.feed,
  (feed) => feed,
);

export const selectFeedPagination = createSelector(
  (state: IRootState) => state.feed,
  (feed) => ({
    nextCursor: feed.nextCursor,
    prevCursor: feed.prevCursor,
    total: feed.total,
  }),
);

export const selectFeedStatuses = createSelector(
  selectFeed,
  (feed) => feed.statuses,
);

export const selectFeedItemStatus = curryRight(
  createSelector(
    [selectFeed, (_, feedItemId: string) => feedItemId],
    (feed, feedItemId) => ({
      update: feed.statuses.update[feedItemId] || {},
      remove: feed.statuses.remove[feedItemId] || {},
      pin: feed.statuses.pin[feedItemId] || {},
    }),
  ),
  2,
);

export const selectDidItemChange = curryRight(
  createSelector(
    [selectFeed, (_: IRootState, id: string | undefined) => id],
    (feed, id) => {
      if (!id) {
        return false;
      }

      const status = feed.statuses.update[id];

      return status && !status.pending;
    },
  ),
  2,
);

export const selectIsItemCreating = createSelector(
  selectFeed,
  (feed) => feed.statuses.create.pending,
);

export const selectIsItemUpdating = curryRight(
  createSelector(
    [selectFeed, (_: IRootState, id: string | undefined) => id],
    (feed, id) => {
      if (!id) {
        return false;
      }

      return feed.statuses.update[id]?.pending;
    },
  ),
  2,
);

export const selectDidItemCreate = createSelector(selectFeed, (feed) => {
  const status = feed.statuses.create;

  return status.itemId;
});

export const selectFeedItem = (state: IRootState, id: string) =>
  selectors.selectById(state, id);

export const selectFeedItems = (state: IRootState) =>
  selectors.selectAll(state);

export const selectPinnedItem = createSelector(selectFeedItems, (items) =>
  items.find((item) => !!item.pin),
);

export const selectReactions = curryRight(
  createSelector(selectFeedItem, (item) => item?.reactions),
  2,
);

export const selectReactedUsers = curryRight(
  createSelector(selectReactions, (reactions) =>
    reactions?.reactions.map((reaction) => reaction.user),
  ),
  2,
);

export const selectDraft = createSelector(selectFeed, (feed) => feed.draft);

export const selectFeedPermissions = createSelector(
  selectFeed,
  (feed) => feed.permissions,
);
