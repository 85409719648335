import React from 'react';
import { useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupNotificationsSettingsSave } from '@wix/bi-logger-groups/v2';

import {
  NotificationChannel,
  NotificationSettings as NotificationSettingsType,
} from '@wix/ambassador-social-groups-v1-notification-settings/types';
import { Button } from 'wui/Button';
import { Fade } from 'wui/Fade';
import { Drawer } from 'wui/Drawer';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogActions } from 'wui/DialogActions';
import { DialogContent } from 'wui/DialogContent';
import { DialogContainer } from 'wui/DialogContainer';
import { Tabs, TabsSkin, TabsVariant } from 'wui/Tabs';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import {
  selectAllNotificationsSettings,
  selectAreNotificationsSettingsLoading,
  selectAreNotificationsSettingsUpdating,
} from 'store/selectors';
import type { INotificationSettings } from 'store/groups/types';

import { GROUP_ACTIONS_SIDE_PANEL } from '../dataHooks';

import { ChannelSettings } from './ChannelSettings';

import { bootstrapBIChanges } from './bi';

interface INotificationSettingsProps {
  groupId: string;
  isOpen: boolean;

  onClose(): void;
}

export function NotificationSettings(props: INotificationSettingsProps) {
  const { group$ } = useController();

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const [activeTab, setActiveTab] = React.useState<NotificationChannel>(
    NotificationChannel.WEB,
  );

  const isLoading = useSelector(
    selectAreNotificationsSettingsLoading(props.groupId),
  );
  const isUpdating = useSelector(
    selectAreNotificationsSettingsUpdating(props.groupId),
  );

  const savedNotificationSettings = useSelector(
    selectAllNotificationsSettings(props.groupId),
  );

  const [notificationSettings, setNotificationSettings] =
    React.useState<INotificationSettings>({});

  React.useEffect(() => {
    setNotificationSettings({
      ...savedNotificationSettings,
      ...notificationSettings,
    });
  }, [savedNotificationSettings]);

  React.useEffect(() => {
    if (!savedNotificationSettings[activeTab] && props.isOpen) {
      group$.fetchNotificationsSettings(props.groupId, activeTab);
    }
  }, [activeTab, props.isOpen]);

  useDidUpdate(() => {
    if (!isUpdating) {
      handleClose();
    }
  }, [isUpdating]);

  return (
    <Drawer
      hideCloseButton={false}
      isOpen={props.isOpen}
      onClose={handleClose}
      data-hook={GROUP_ACTIONS_SIDE_PANEL}
      anchor="right"
    >
      <DialogContainer>
        <DialogTitle title={t('groups-web.notifications')} />
        <Tabs
          variant={TabsVariant.fullWidth}
          value={activeTab}
          onChange={setActiveTab}
          skin={TabsSkin.fullUnderline}
          items={[
            {
              id: NotificationChannel.WEB,
              title: t('groups-web.notifications.website'),
            },
            {
              id: NotificationChannel.EMAIL,
              title: t('groups-web.notifications.email'),
            },
            {
              id: NotificationChannel.MOBILE,
              title: t('groups-web.notifications.mobile'),
            },
          ]}
        />

        <DialogContent divider>
          <SwitchTransition>
            <Fade key={activeTab} timeout={300}>
              <ChannelSettings
                settings={notificationSettings[activeTab] || []}
                channel={activeTab}
                onChange={handleActiveChannelSettingsChange}
              />
            </Fade>
          </SwitchTransition>
        </DialogContent>

        <DialogActions>
          <Button outlined variant="basic" fullWidth onClick={handleClose}>
            {t('groups-web.cancel')}
          </Button>
          <Button
            variant="basic"
            fullWidth
            onClick={handleSave}
            disabled={isLoading || isUpdating}
            loading={isLoading || isUpdating}
          >
            {t('groups-web.save')}
          </Button>
        </DialogActions>
      </DialogContainer>
    </Drawer>
  );

  function handleClose() {
    setActiveTab(NotificationChannel.WEB);
    setNotificationSettings(savedNotificationSettings);
    props.onClose();
  }

  function handleActiveChannelSettingsChange(
    newSettings: NotificationSettingsType[],
  ) {
    setNotificationSettings((notificationSettings) => ({
      ...notificationSettings,
      [activeTab]: newSettings,
    }));
  }

  function handleSave() {
    Object.keys(notificationSettings).forEach((channel) => {
      group$.updateNotificationsSettings(
        props.groupId,
        channel as NotificationChannel,
        notificationSettings[channel as NotificationChannel] || [],
      );
    });

    bootstrapBIChanges(savedNotificationSettings, notificationSettings).forEach(
      (channel) => {
        bi.report(
          groupNotificationsSettingsSave({
            ...channel,
            origin: 'save_btn',
            userEntry: 'site',
            groupId: props.groupId,
          }),
        );
      },
    );
  }
}

NotificationSettings.displayName = 'NotificationSettings';
