import {
  Group,
  Query,
  SortOrder as GroupsSortOrder,
} from '@wix/ambassador-social-groups-v2-group/types';

import type {
  PagingMetadata,
  RequestStatus,
} from '@wix/ambassador-social-groups-v2-join-group-request/types';
import { RequestStatus as GroupRequestStatus } from '@wix/ambassador-social-groups-v2-group-request/types';
import {
  MembershipStatus,
  Role,
} from '@wix/ambassador-social-groups-v2-group-member/types';

import type { IMember } from '../members/types';
import { IFeedPermissions } from '../feed/types';

export const SortOrder = GroupsSortOrder;

export enum EGroupPartition {
  ALL = 'all',
  JOINED = 'joined',
  SUGGESTED = 'suggested',
}

export interface IQueryGroupsRequest extends Query {
  partition?: EGroupPartition;
}

export interface IQueryRecommendedGroupsRequest extends Query {}

export interface IGroup extends Omit<Group, 'createdBy'>, IGroupRelation {
  createdBy?: string;
  status: GroupRequestStatus;
  permissions: IPermissions;
}

export interface IGroupRelation {
  role: Role;
  membership: MembershipStatus;
}

export interface IPermissions {
  feed?: IFeedPermissions;
}

export interface IUserAnswer {
  id: string;
  required: boolean;
  question: string;
  answer: string;
}

export interface IJoinRequest {
  profile: IMember;
  createdDate?: Date;
  status: RequestStatus;
  answers?: IUserAnswer[];
}

export interface IListPendingRequestsResponse {
  metadata: PagingMetadata;
  requests: IJoinRequest[];
}

export interface IQueryJoinedGroupsResponse {
  metadata: PagingMetadata;
  groups: IGroup[];
}
