import React from 'react';
import cls from 'classnames';

import {
  Tabs as TPATabs,
  TabsAlignment,
  TabsSkin,
  TabsVariant,
} from 'wix-ui-tpa/cssVars';

import classes from './Tabs.scss';
import { classes as stClasses } from './Tabs.st.css';

interface ITabsPropsWithGeneric<ValueType>
  extends Omit<
    React.ComponentProps<typeof TPATabs>,
    'activeTabIndex' | 'onTabClick'
  > {
  value: ValueType;

  onChange: (id: ValueType) => void;
}

export function Tabs<T extends string>(props: ITabsPropsWithGeneric<T>) {
  const { value, onChange, className, ...rest } = props;
  const [activeTabIndex, setActiveTabIndex] = React.useState(
    props.items.findIndex((item) => item.id === value),
  );

  React.useEffect(() => {
    const index = props.items.findIndex((item) => item.id === value);

    setActiveTabIndex(index);
  }, [value]);

  return (
    <TPATabs
      onTabClick={handleTabClick}
      activeTabIndex={activeTabIndex}
      className={cls(classes.root, stClasses.root, className)}
      {...rest}
    />
  );

  function handleTabClick(index: number) {
    const active = props.items[index];

    setActiveTabIndex(index);

    return onChange(active.id as T);
  }
}

Tabs.displayName = 'wui/Tabs';

export { TabsAlignment, TabsSkin, TabsVariant };
export type { TabItem } from 'wix-ui-tpa/cssVars';
