import React from 'react';
import { useSelector } from 'react-redux';

import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupsAddMemberClicked } from '@wix/bi-logger-groups/v2';

import { selectAddStatuses, selectGroupName } from 'store/selectors';

import { useController } from 'common/context/controller';
import { useDidUpdate } from 'common/hooks';

import { Search as SearchIcon } from '@wix/wix-ui-icons-common/on-stage';

import { Button } from 'wui/Button';
import { InputDialog } from 'wui/InputDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogActions } from 'wui/DialogActions';
import { Box } from 'wui/Box';
import { TextField } from 'wui/TextField';

import { SuggestedMembersList } from './SuggestedMembersList';

interface IProps extends React.ComponentProps<typeof InputDialog> {
  groupId: string;
}

export function AddMembersDialog(props: IProps) {
  const { groupId, ...rest } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();

  const { members$ } = useController();

  const groupName = useSelector(selectGroupName(groupId));
  const status = useSelector(selectAddStatuses);

  const [selected, setSelected] = React.useState<string[]>([]);
  const [search, setSearch] = React.useState('');

  const isLoading = selected.some((memberId) => status[memberId]?.loading);

  React.useEffect(() => {
    if (!props.isOpen) {
      setSearch('');
      setSelected([]);
    }
  }, [props.isOpen]);

  useDidUpdate(() => {
    if (!isLoading) {
      props.onClose();
    }
  }, [isLoading]);

  return (
    <InputDialog {...rest}>
      <DialogTitle
        title={t('groups-web.dialogs.add-members.title', {
          count: selected.length,
        })}
        subtitle={t('groups-web.dialogs.add-members.subtitle', { groupName })}
      />
      <Box padding="SP0 SP6" direction="vertical">
        <TextField
          value={search}
          withClearButton
          onClear={resetSearch}
          prefix={<SearchIcon />}
          placeholder={t('groups-web.search.placeholder')}
          onChange={handleSearchChange}
        />
      </Box>
      <DialogContent divider>
        <SuggestedMembersList
          onReset={resetSearch}
          groupId={groupId}
          search={search}
          selected={selected}
          onSelect={handleSelect}
        />
      </DialogContent>
      <DialogActions>
        <Button
          outlined
          variant="basic"
          fullWidth={isMobile}
          onClick={props.onClose}
        >
          {t('groups-web.cancel')}
        </Button>
        <Button
          variant="basic"
          fullWidth={isMobile}
          onClick={handleSubmit}
          disabled={!selected.length || isLoading}
          loading={isLoading}
        >
          {t('groups-web.dialogs.add-members.submit.label')}
        </Button>
      </DialogActions>
    </InputDialog>
  );

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  function handleSelect(selected: string[]) {
    setSelected(selected);
  }

  function resetSearch() {
    setSearch('');
  }

  function handleSubmit() {
    members$.add(groupId, selected);
    bi.report(
      groupsAddMemberClicked({
        origin: 'modal_plus_add_btn',
        groupId,
      }),
    );
  }
}

AddMembersDialog.displayName = 'AddMembersDialog';
