import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupsAddMemberClicked } from '@wix/bi-logger-groups/v2';

import { selectCanAddInviteMembers } from 'store/selectors';

import { Button } from 'wui/Button';
import { PlusSmall as PlusIconSmall } from '@wix/wix-ui-icons-common/on-stage';

import { InviteMembersDialog } from './InviteMembersDialog';
import { INVITE_MEMBERS_BUTTON } from './dataHooks';

interface IInviteMembersProps {
  groupId: string;
}

export function InviteMembers(props: IInviteMembersProps) {
  const { groupId } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const canAddInvite = useSelector(selectCanAddInviteMembers(groupId));

  const [isOpen, setIsOpen] = useState(false);

  if (!canAddInvite) {
    return null;
  }

  return (
    <>
      <Button
        fullWidth
        outlined
        prefixIcon={isMobile ? undefined : <PlusIconSmall />}
        onClick={openModal}
        data-hook={INVITE_MEMBERS_BUTTON}
        area-label={t('groups-web.a11y.invite-members')}
      >
        {t('groups-web.group.actions.invite-members.title')}
      </Button>
      <InviteMembersDialog
        isOpen={isOpen}
        groupId={groupId}
        onClose={closeModal}
      />
    </>
  );

  function openModal() {
    setIsOpen(true);

    bi.report(
      groupsAddMemberClicked({
        groupId,
        origin: 'group_member_icon_btn',
      }),
    );
  }

  function closeModal() {
    setIsOpen(false);
  }
}

InviteMembers.displayName = 'InviteMembers';
